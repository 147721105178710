<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Liste des visites</h3>
            </div>
          </div>
          <b-card-body>
            <!--begin: Datatable-->
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-select
                    style="width: 50px"
                    v-model="perPage"
                    :options="options"
                    size="sm"
                  ></b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-input
                    class="float-right mb-2"
                    style="width: 200px;"
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Rechercher"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col>
                <b-table
                  bordered
                  hover
                  outlined
                  :items="visits"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  responsive="sm"
                >
                  <template v-slot:cell(annonce.categorie)="data">
                    <span
                      v-text="data.item.annonce.categorie"
                      style="text-transform: capitalize"
                    ></span>
                  </template>
                  <template
                    #cell(etat)="data"
                    style="text-transform: capitalize"
                  >
                    <span v-if="data.item.etat === 0">
                      <span class="badge badge-dark">{{ etats["0"] }}</span>
                    </span>
                    <span v-if="data.item.etat === 1">
                      <span class="badge badge-primary">{{ etats["1"] }}</span>
                    </span>
                    <span v-if="data.item.etat === 2">
                      <span class="badge badge-danger">{{ etats["2"] }}</span>
                    </span>
                    <span v-if="data.item.etat === 3">
                      <span class="badge badge-success">{{ etats["3"] }}</span>
                    </span>
                  </template>
                  <template v-slot:cell(user)="data">
                    <span
                      v-text="data.item.user.nom"
                      style="text-transform: capitalize; margin-right: 10px;"
                    ></span>
                    <span
                      v-text="data.item.user.telephone_1"
                      style="text-transform: capitalize"
                    ></span>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <router-link
                      class="btn btn-info btn-sm"
                      :to="{
                        name: 'ad.show',
                        params: { id: data.item.annonce.id }
                      }"
                    >
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <b-button
                      size="sm"
                      variant="danger"
                      :disabled="active"
                      @click="remove(data.item)"
                    >
                      <b-spinner small v-show="active"></b-spinner>&nbsp;
                      <i class="fas fa-trash" v-show="!active"></i>
                    </b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :fields="fields"
                  aria-controls="my-table"
                  class="float-right mt-2"
                ></b-pagination>
              </b-col>
            </b-row>
            <!--end: Datatable-->
          </b-card-body>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Index",
  data() {
    return {
      // DataTable option
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        { key: "annonce.code", label: "Annonce" },
        { key: "annonce.type.nom", label: "Type" },
        { key: "annonce.categorie", label: "Catégorie" },
        { key: "annonce.user.nom", label: "Nom du propriétaire" },
        { key: "etat" },
        {
          key: "etat",
          label: "Etat"
        },
        {
          key: "user",
          label: "Demandeur"
        },
        "Actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      etats: {
        0: "En Attente...",
        1: "Progammer",
        2: "Rejeter",
        3: "Terminer"
      },
      active: false
    };
  },
  computed: {
    ...mapGetters({
      visits: "getVisits"
    }),
    rows() {
      return this.visits.length;
    }
  },
  beforeCreate() {
    this.$store.dispatch("allVisits");
  },
  methods: {
    showInfo(visit) {
      Swal.fire({
        title: "Data et heure de la visite",
        text: visit.date_prevue + " à " + visit.heure_prevue,
        icon: "info",
        confirmButtonText: "Ok"
      });
    },
    remove(data) {
      console.log(data);
    }
  }
};
</script>

<style scoped></style>
